import React from 'react';
import Logo from '../images/dodizajnu-web.svg';
import Menu from '../components/Menu';
import styled from 'styled-components';
import InnerContainer from '../components/InnerContainer';

const StyledTopBar = styled('div')`
	@media print {
		display: none;
	}
`;

const StyledTopBarContent = styled('div')`
	display: flex;
	align-items: top;
`;

const LogoImage = styled('img')`
	height: 60px;
	margin-top: 0.3em;
	position: relative;
	z-index: 3;

	@media (max-width: 580px) {
		height: 40px;
	}
`;

const StyledMenuContainer = styled('div')`
	flex-grow: 1;
`;

const TopBar = () => (
	<StyledTopBar>
		<InnerContainer>
			<StyledTopBarContent>
				<StyledMenuContainer>
					<h1>
						<a href="/">
							<LogoImage src={Logo} alt="do dizjanu" />
						</a>
					</h1>
				</StyledMenuContainer>
				<Menu />
			</StyledTopBarContent>
		</InnerContainer>
	</StyledTopBar>
);

export default TopBar;
