import styled from 'styled-components';

const StyledInnerContainer = styled('div')`
	margin: 0 2em;
	flex-grow: 1;
`;

const InnerContainer = StyledInnerContainer;

export default InnerContainer;
