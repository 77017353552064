import React from 'react';
import styled from 'styled-components';
import InnerContainer from './InnerContainer';
import TextLogo from '../images/dodizajnu-text.svg';
import IconInstagram from '../components/IconInstagram';
import IconFacebook from '../components/IconFacebook';

const StyledFooter = styled('footer')`
	border-top: 3px solid #000;
	margin-top: 3em;
	display: flex;
	align-items: center;
	padding: 1em 0;

	@media print {
		display: none;
	}
`;

const StyledImage = styled('img')`
	display: block;
	height: 30px;
	margin: 0;
`;

const StyledLink = styled('a')`
	display: block;
	padding: 0 10px;
`;

const SmallLinksContainer = styled('div')`
	display: flex;
	justify-content: flex-end;
`;

const StyledSmallLink = styled('a')`
	color: #9f9f9f;
	font-size: 0.7em;
`;

const StyledFooterContent = styled('div')`
	display: flex;
	justify-content: space-between;
`;

const StyledFooterSocialMedia = styled('div')`
	display: flex;
	align-items: flex-end;
`;

const StyledP = styled('p')`
	font-size: 0.8em;
	font-weight: 600;
	margin: 0;
	margin-left: 10px;
`;

const Footer = () => (
	<StyledFooter>
		<InnerContainer>
			<StyledFooterContent>
				<StyledLink href="/">
					<StyledImage src={TextLogo} />
				</StyledLink>
				<StyledFooterSocialMedia>
					<StyledLink
						href="https://www.facebook.com/dodizajnu"
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconFacebook />
					</StyledLink>
					<StyledLink
						href="https://www.instagram.com/dodizajnu/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconInstagram />
					</StyledLink>
					<StyledP>do dizajnu © 2018</StyledP>
				</StyledFooterSocialMedia>
			</StyledFooterContent>
			<SmallLinksContainer>
				<StyledSmallLink href="/polityka-prywatnosci">
					Polityka prywatności i polityka cookies
				</StyledSmallLink>
			</SmallLinksContainer>
		</InnerContainer>
	</StyledFooter>
);

export default Footer;
