import * as React from 'react';

const IconClose = () => (
	<svg width="24px" height="23px" viewBox="0 0 24 23">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-277.000000, -20.000000)" fill="#FFFFFF">
				<g id="close" transform="translate(277.000000, 19.000000)">
					<rect
						id="Rectangle-5"
						transform="translate(12.000000, 12.500000) rotate(-315.000000) translate(-12.000000, -12.500000) "
						x="-3"
						y="11"
						width="30"
						height="3"
						rx="1.5"
					/>
					<rect
						id="Rectangle-5"
						transform="translate(12.000000, 12.500000) rotate(-45.000000) translate(-12.000000, -12.500000) "
						x="-3"
						y="11"
						width="30"
						height="3"
						rx="1.5"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default IconClose;
