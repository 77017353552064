import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import IconInstagram from '../components/IconInstagram';
import IconFacebook from '../components/IconFacebook';
// import IconSearch from '../components/IconSearch'
import IconMenu from '../components/IconMenu';
import IconClose from '../components/IconClose';

const StyledMenu = styled('ul')`
	display: flex;
	position: relative;
	z-index: 2;
`;

const StyledSeparator = styled('svg')`
	display: block;
	height: 30px;
	margin-top: 1em;
	margin-right: 0.5em;
`;

const StyledMenuLink = styled('a')`
	padding: 1em 0.7em;
`;

const StyledMenuItem = styled('div')`
	font-family: 'Work Sans', sans-serif;
	font-weight: bold;

	a {
		color: #000;
		text-decoration: none;
		padding: 1em;
		display: block;

		&:hover {
			transform: scale(1.1);
			transition: transform 0.1s;
		}
	}
`;

const StyledNavContainer = styled('div')`
	display: flex;

	@media (max-width: 890px) {
		display: ${props => (props.opened ? 'block' : 'none')};
		position: fixed;
		flex-direction: column;
		z-index: 10;
		top: 0;
		right: 0;
		bottom: 0;
		width: 90%;
		background-color: #000;
		color: #fff;

		${StyledMenu} {
			flex-direction: column;
			align-items: flex-end;
		}

		${StyledMenuItem} {
			font-size: 1.9rem;

			a {
				color: #fff !important;
				padding: 0.7em 1em;
			}
		}

		${StyledMenuLink} svg * {
			stroke: #fff;
		}
	}
`;

const StyledIconButton = styled('button')`
	background: transparent;
	border: 0;
	padding: 1em;
	cursor: pointer;
`;

const StyledMenuSocialMedia = styled('div')`
	flex-grow: 0;
	margin-left: 50px;
	display: flex;
	align-items: flex-start;

	@media (max-width: 890px) {
		justify-content: flex-end;
		margin-right: 1em;
	}
`;

const StyledCloseContainer = styled('div')`
	display: flex;
	justify-content: flex-end;
	padding: 0.8em;
`;

const StyledMenuButtonContainer = styled('div')`
	display: none;

	@media (max-width: 890px) {
		display: block;
		padding-top: 1em;
	}
`;

class Menu extends React.Component {
	state = {
		menuOpened: false
	};

	handleMenuClick = () => {
		this.setState({
			menuOpened: true
		});
	};

	handleCloseClick = () => {
		this.setState({
			menuOpened: false
		});
	};

	render() {
		return (
			<React.Fragment>
				<StyledNavContainer opened={this.state.menuOpened}>
					<StyledCloseContainer>
						<StyledIconButton
							aria-label="close menu"
							onClick={this.handleCloseClick}
						>
							<IconClose />
						</StyledIconButton>
					</StyledCloseContainer>
					<nav>
						<StyledMenu>
							<StyledMenuItem>
								<Link to="/o-projekcie">O projekcie</Link>
							</StyledMenuItem>
							<StyledMenuItem>
								<Link to="/o-nas">O nas</Link>
							</StyledMenuItem>
							<StyledMenuItem>
								<Link to="/kontakt">Kontakt</Link>
							</StyledMenuItem>
						</StyledMenu>
					</nav>
					<StyledMenuSocialMedia>
						{/* <StyledIconButton aria-label="search">
                            <IconSearch />
                        </StyledIconButton> */}
						<StyledSeparator viewBox="0 0 1 26">
							<line
								x1="0"
								x2="0"
								y1="0"
								y2="26"
								stroke="#000"
								strokeWidth="1"
							/>
						</StyledSeparator>
						<StyledMenuLink
							href="https://www.facebook.com/dodizajnu"
							target="_blank"
							rel="noopener noreferrer"
						>
							<IconFacebook />
						</StyledMenuLink>
						<StyledMenuLink
							href="https://www.instagram.com/dodizajnu/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<IconInstagram />
						</StyledMenuLink>
					</StyledMenuSocialMedia>
				</StyledNavContainer>
				<StyledMenuButtonContainer>
					<StyledIconButton
						aria-label="show menu"
						onClick={this.handleMenuClick}
					>
						<IconMenu />
					</StyledIconButton>
				</StyledMenuButtonContainer>
			</React.Fragment>
		);
	}
}

export default Menu;
