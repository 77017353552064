import * as React from 'react';

const IconMenu = () => (
	<svg width="30px" height="13px" viewBox="0 0 30 13">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-270.000000, -20.000000)" fill="#000000">
				<g transform="translate(270.000000, 20.000000)">
					<rect id="Rectangle-5" x="0" y="0" width="30" height="3" />
					<rect id="Rectangle-5" x="0" y="10" width="30" height="3" />
				</g>
			</g>
		</g>
	</svg>
);

export default IconMenu;
