import * as React from 'react';

const IconIntagram = () => (
	<svg viewBox="0 0 34 34" width="30px">
		<rect
			x="2"
			y="2"
			width="30"
			height="30"
			fill="none"
			stroke="#000"
			strokeWidth="3"
			rx="9"
			ry="9"
		/>
		<circle
			cx="17"
			cy="17"
			r="7"
			stroke="#000"
			strokeWidth="3"
			fill="none"
		/>
		<circle cx="25" cy="9" r="2" strokeWidth="0" fill="#000" />
	</svg>
);

export default IconIntagram;
