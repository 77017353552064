import * as React from 'react';

const IconFacebook = () => (
	<svg viewBox="0 0 16 30" height="30px">
		<path
			strokeWidth="6"
			d="M 14,3 L 12,3 A 5,5 0,0,0 7,8 L 7,27"
			fill="none"
			stroke="#000"
		/>
		<line x1="1" x2="14" y1="13" y2="13" stroke="#000" strokeWidth="6" />
	</svg>
);

export default IconFacebook;
